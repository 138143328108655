import React, { useEffect } from "react"

import g_img1 from "../images/g_website.045.jpg";
import g_img2 from "../images/g_website.209.jpg";
import g_img3 from "../images/g_website.203.jpg";

const ThreeUp = () => {
    
    useEffect(() => {

        const outlines = document.querySelectorAll(".title p.outline");

        function addClass(elements, myClass) {

            // if there are no elements, we're done
            if (!elements) { return; }
        
            // if we have a selector, get the chosen elements
            if (typeof(elements) === 'string') {
            elements = document.querySelectorAll(elements);
            }
        
            // if we have a single DOM element, make it an array to simplify behavior
            else if (elements.tagName) { elements=[elements]; }
        
            // add class to all chosen elements
            for (var i=0; i<elements.length; i++) {
        
                // if class is not already found
                if ( (' '+elements[i].className+' ').indexOf(' '+myClass+' ') < 0 ) {
            
                    // add class
                    elements[i].className += ' ' + myClass;
                }
            }
        }

        const delayLoop = (fn, delay) => {
            return (outline, i) => {
              setTimeout(() => {
                addClass(outline,'show');
              }, i * 1000);
            }
          };

        function showEm(outline) {
            addClass(outline,'show');
        }
        // showEm();

        
        
        setInterval(() => {
            outlines.forEach(delayLoop(showEm, 500));

            outlines.forEach(outline => {
                outline.classList.remove("show");
            });      
        }, 5000)
    });

    return (
        <div id="shopify-section-home-second-menu" className="shopify-section threeUp">
            <section className="second-menu purple-bg">
                <div className="wrapper">
                    <div className="top flex space-between">
                        <div className="headline">
                            <p className="text-xl gopher-font uppercase">Leading teams to success</p>
                        </div>
                        <div className="title flex column">
                            <p className="outline text-4xl gopher-font uppercase">
                                Kris Chase
                            </p>
                            <p className="outline text-4xl gopher-font uppercase">
                                Kris Chase
                            </p>
                            <p className="outline text-4xl gopher-font uppercase">
                                Kris Chase
                            </p>
                            <p className="outline text-4xl gopher-font uppercase">
                                Kris Chase
                            </p>
                            <p className="outline text-4xl gopher-font uppercase">
                                Kris Chase
                            </p>
                        </div>
                    </div>
                    <div className="flex grid three">
                        <div className="grid-item one-third">
                            <div className="plane-wrapper">
                                <a href="https://krischase.com" className="plane-inner">
                                    <div className="hover-plane image">
                                        <img crossorigin="anonymous" src={g_img1} alt="" />
                                    </div>
                                </a>
                            </div>
                            {/* <div className="link gopher-font text-xs uppercase">
                                <a href="/tagged/blog-recipes">recipes</a>
                            </div> */}
                            <div className="title text-2xl tandelle-font uppercase">
                                {/* <a href="/blogs/second-menu/thai-mushroom-larb-recipe"> */}
                                Plan your mission, goals and objectives
                                {/* </a> */}
                            </div>
                            <p> </p>
                        </div>
                        <div className="grid-item one-third">
                            <div className="plane-wrapper">
                                <a href="https://krischase.com" className="plane-inner">
                                    <div className="hover-plane image">
                                        <img crossorigin="anonymous" src={g_img2} alt="" />
                                    </div>
                                </a>
                            </div>
                            {/* <div className="link gopher-font text-xs uppercase">
                                <a href="/tagged/blog-tastemakers">tastemakers</a>
                            </div> */}
                            <div className="title text-2xl tandelle-font uppercase">
                                {/* <a href="/blogs/second-menu/meet-the-tastemaker-chat-and-ohm-suansilphong-of-fish-cheeks"> */}
                                Analyze industry positioning
                                {/* </a> */}
                            </div>
                            <p> </p>
                        </div>
                        <div className="grid-item one-third">
                            <div className="plane-wrapper">
                                <a href="https://krischase.com" className="plane-inner">
                                    <div className="hover-plane image">
                                        <img crossorigin="anonymous" src={g_img3} alt="" />
                                    </div>
                                </a>
                            </div>
                            {/* <div className="link gopher-font text-xs uppercase">
                                <a href="/tagged/blog-recipes">recipes</a>
                            </div> */}
                            <div className="title text-2xl tandelle-font uppercase">
                                {/* <a href="/blogs/second-menu/filipino-pork-sisig-recipe"> */}
                                Evaluate, modify, repeat
                                {/* </a> */}
                            </div>
                            <p> </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default ThreeUp;