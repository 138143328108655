import React from "react"

class Intro extends React.Component {

    render () {
        return (
            <section className="intro">
                <div className="row">
                    <div className="container">
                        <p> 
                            {/* With over 10 years in the industry and a background in development, systems administration, information security and a knack for automating all the things, I've acquired a considerable set of skills and know-how. I also have just the right mindset and ability to lead a team of developers to motivate them to achieve their best. */}
                            With more than a decade of experience,
                            I have become a leader in building teams and award winning digital
                            experiences, through web development, IoT and apps. That’s not a
                            brag, it’s a promise: to deliver tomorrow’s technology
                            solutions — today.
                        </p>
                    </div>
                </div>
            </section>
        )
    }
};

export default Intro;