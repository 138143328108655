import React from "react"

class Experience extends React.Component {

    render () {
        return (
            <section className="experience">
                <div className="container">

                    <div className="row">
                        <h2>Over 15 years of experience</h2>
                        <p>
                        Engineering Leader | Leading Through Empathy | Web/Mobile Development | I am passionate about building high performing teams through mentoring, coaching, and leadership development.
                        </p> 
                        <p>For more details, head over to my <a targe="_blank" rel="noreferrer" href="https://www.linkedin.com/in/krisrchase/">LinkedIn profile.</a></p>
                    </div>
                    <div className="row">

                        <div class="css-1lbi719 e3crczr5">
                            <div class="css-n5ny18 e3crczr6">
                                <h2 id="experience">Experience</h2>
                                <ul>
                                    <li><span class="year">2022—TODAY</span><a href="https://www.talentsystems.com">Talent Systems</a><br/><br/>VP of Software Engineering<br/>&nbsp;</li>
                                    <li><span class="year">2021—2022</span><a href="https://www.publicsq.com">PublicSq.</a><br/><br/>VP of Engineering<br/>&nbsp;</li>
                                    <li><span class="year">2018—2021</span><a href="https://www.weareenvoy.com">Envoy</a><br/><br/>VP of Technology<br/>&nbsp;</li>
                                    <li><span class="year">2013—2018</span><a href="https://www.gigasavvy.com">Gigasavvy</a><br/><br/>Partner & <br/>Development Director<br/>&nbsp;</li>
                                    <li><span class="year">2008-2013</span><a href="https://www.werbewelt.de/">IBM</a><br/><br/>Associate Software <br/> Product Professional<br/>Systems Administrator <br/> Developer <br/>&nbsp;</li>
                                    <li><span class="year">2007—2020</span>Freelance / Consulting<br/>&nbsp;</li>
                                </ul>
                            </div>

                            <div class="css-n5ny18 e3crczr6">
                                <h2 id="awards">Awards</h2>
                                <ul>
                                    
                                    <li><span class="year">2019</span><strong>Gold Addy Award - VirginOrbit.com</strong></li>
                                    <li><span class="year">2019</span><strong>Gold Addy Award - CremoCompany.com</strong></li>

                                    <li><span class="year">2018</span><strong>Gold Addy Award - OrchidEssentials.com</strong></li>
                                    <li><span class="year">2018</span><strong>Bronze Addy Award - JohnnyRockets.com</strong></li>
                                    <li><span class="year">2018</span><strong>Bronze Addy Award - Bruxie.com</strong></li>

                                    
                                    
                                    <li><span class="year">2016</span><strong>Gold Addy Award - Sugarfina.com</strong></li>
                                    <li><span class="year">2016</span><strong>Gold Addy Award - PearSports.com</strong></li>
                                    <li><span class="year">2016</span><strong>Gold Addy Award - LumeCube.com.com</strong></li>
                                    <li><span class="year">2016</span><strong>Gold Addy Award - Ullushop.com</strong></li>
                                    
                                    <li><span class="year">2016</span><strong>Silver Addy Award - LumeCube.com</strong></li>
                                    <li><span class="year">2016</span><strong>Silver Addy Award - DitaEyewear.com</strong></li>
                                    <li><span class="year">2016</span><strong>Silver Addy Award - Gigasavvy.com</strong></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            )
        }   
    };

export default Experience;