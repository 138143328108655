import React from "react"

class Ticker extends React.Component {

    render () {
        return (
            <div className="ticker">
                <div className="marquee flex red-bg yellow-color">
                    <div className="marquee_list flex">
                        <span className="marquee_item flex text-sm uppercase gopher-font">- IoT</span>
                        <span className="marquee_item flex text-sm uppercase gopher-font">- Websites</span>
                        <span className="marquee_item flex text-sm uppercase gopher-font">- Applications</span>
                        <span className="marquee_item flex text-sm uppercase gopher-font">- Teams</span>
                    </div>
                    <div className="marquee_list flex">
                        <span className="marquee_item flex text-sm uppercase gopher-font">- IoT</span>
                        <span className="marquee_item flex text-sm uppercase gopher-font">- Websites</span>
                        <span className="marquee_item flex text-sm uppercase gopher-font">- Applications</span>
                        <span className="marquee_item flex text-sm uppercase gopher-font">- Teams</span>
                    </div>
                    <div className="marquee_list flex">
                        <span className="marquee_item flex text-sm uppercase gopher-font">- IoT</span>
                        <span className="marquee_item flex text-sm uppercase gopher-font">- Websites</span>
                        <span className="marquee_item flex text-sm uppercase gopher-font">- Applications</span>
                        <span className="marquee_item flex text-sm uppercase gopher-font">- Teams</span>
                    </div>
                    <div className="marquee_list flex">
                        <span className="marquee_item flex text-sm uppercase gopher-font">- IoT</span>
                        <span className="marquee_item flex text-sm uppercase gopher-font">- Websites</span>
                        <span className="marquee_item flex text-sm uppercase gopher-font">- Applications</span>
                        <span className="marquee_item flex text-sm uppercase gopher-font">- Teams</span>
                    </div>
                </div>
            </div>
        )
    }
};

export default Ticker;

