import React from "react"
import Layout from "../components/layout"

import Hero from "../components/hero"
import Intro from "../components/intro"
import Ticker from "../components/ticker"
import Experience from "../components/experience"
import Brands from "../components/brands"
import Footer from "../components/footer"
import ThreeUp from "../components/threeUp"


const IndexPage = () => (
  
  <Layout>
    <Hero />

    <Intro />

    <Ticker />
    
    <ThreeUp />

    <Experience />

    <Brands />

    <Footer />
  </Layout>
)

export default IndexPage
