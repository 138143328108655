import React from "react"

class Footer extends React.Component {

    render () {
        return (
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="c-page-footer o-section -black">
                            <div className="c-page-footer_heading o-container-heading" data-module="Glitch">   
                                <a href="/blog/" data-transition="CasestudyFooterTransition" className="c-page-footer_title o-h1" data-pjax-state="">
                                    <span className="c-page-footer_title_line">
                                        <span className="c-page-footer_title_inner o-glitch-text">
                                            <span className="o-glitch-text_main" data-text="Larger">
                                                Blog
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            )
        }   
    };

export default Footer;