import React from "react"
import Logos from "./getLogos"


class Brands extends React.Component {

    render () {
        return (

            <section class="carousel-logo-grid bg-black">
                <div class="container-overflow">
                    <h2>Brands I’ve worked with</h2>
                    <div class="logos">

                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2019/05/VirginOrbit.png" srcset="https://www.gigasavvy.com/app/uploads/2019/05/VirginOrbit.png 1x, https://www.gigasavvy.com/app/uploads/2019/05/VirginOrbit@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2019/05/HiChew.png" srcset="https://www.gigasavvy.com/app/uploads/2019/05/HiChew.png 1x, https://www.gigasavvy.com/app/uploads/2019/05/HiChew@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2016/08/johnny-rockets.png" srcset="https://www.gigasavvy.com/app/uploads/2016/08/johnny-rockets.png 1x, https://www.gigasavvy.com/app/uploads/2016/08/johnny-rockets@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2016/08/PetIQ.png" srcset="https://www.gigasavvy.com/app/uploads/2016/08/PetIQ.png 1x, https://www.gigasavvy.com/app/uploads/2016/08/PetIQ@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2019/05/Orchid.png" srcset="https://www.gigasavvy.com/app/uploads/2019/05/Orchid.png 1x, https://www.gigasavvy.com/app/uploads/2019/05/Orchid@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2016/08/FlameBroiler.png" srcset="https://www.gigasavvy.com/app/uploads/2016/08/FlameBroiler.png 1x, https://www.gigasavvy.com/app/uploads/2016/08/FlameBroiler@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2019/05/Wahoos.png" srcset="https://www.gigasavvy.com/app/uploads/2019/05/Wahoos.png 1x, https://www.gigasavvy.com/app/uploads/2019/05/Wahoos@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2016/08/Sugarfina.png" srcset="https://www.gigasavvy.com/app/uploads/2016/08/Sugarfina.png 1x, https://www.gigasavvy.com/app/uploads/2016/08/Sugarfina@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2019/05/Nekter.png" srcset="https://www.gigasavvy.com/app/uploads/2019/05/Nekter.png 1x, https://www.gigasavvy.com/app/uploads/2019/05/Nekter@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2016/08/AmericanCareerCollegelogo.png" srcset="https://www.gigasavvy.com/app/uploads/2016/08/AmericanCareerCollegelogo.png 1x, https://www.gigasavvy.com/app/uploads/2016/08/AmericanCareerCollegelogo@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2019/04/Toshiba_Alt.png" srcset="https://www.gigasavvy.com/app/uploads/2019/04/Toshiba_Alt.png 1x, https://www.gigasavvy.com/app/uploads/2019/04/Toshiba_Alt@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2019/05/WCU.png" srcset="https://www.gigasavvy.com/app/uploads/2019/05/WCU.png 1x, https://www.gigasavvy.com/app/uploads/2019/05/WCU@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2018/11/Chapman-Logo.png" srcset="https://www.gigasavvy.com/app/uploads/2018/11/Chapman-Logo.png 1x, https://www.gigasavvy.com/app/uploads/2018/11/Chapman-Logo@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2016/08/Knotts_Logo.png" srcset="https://www.gigasavvy.com/app/uploads/2016/08/Knotts_Logo.png 1x, https://www.gigasavvy.com/app/uploads/2016/08/Knotts_Logo@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2019/05/Oakwood.png" srcset="https://www.gigasavvy.com/app/uploads/2019/05/Oakwood.png 1x, https://www.gigasavvy.com/app/uploads/2019/05/Oakwood@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2016/08/LumeCube.png" srcset="https://www.gigasavvy.com/app/uploads/2016/08/LumeCube.png 1x, https://www.gigasavvy.com/app/uploads/2016/08/LumeCube@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2016/08/betteru_logo.png" srcset="https://www.gigasavvy.com/app/uploads/2016/08/betteru_logo.png 1x, https://www.gigasavvy.com/app/uploads/2016/08/betteru_logo@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2019/05/Orangewood.png" srcset="https://www.gigasavvy.com/app/uploads/2019/05/Orangewood.png 1x, https://www.gigasavvy.com/app/uploads/2019/05/Orangewood@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2019/05/MarcPro.png" srcset="https://www.gigasavvy.com/app/uploads/2019/05/MarcPro.png 1x, https://www.gigasavvy.com/app/uploads/2019/05/MarcPro@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2016/08/tenet.png" srcset="https://www.gigasavvy.com/app/uploads/2016/08/tenet.png 1x, https://www.gigasavvy.com/app/uploads/2016/08/tenet@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2016/08/OluKai_Logo.png" srcset="https://www.gigasavvy.com/app/uploads/2016/08/OluKai_Logo.png 1x, https://www.gigasavvy.com/app/uploads/2016/08/OluKai_Logo@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2016/08/GFORE_Logo.png" srcset="https://www.gigasavvy.com/app/uploads/2016/08/GFORE_Logo.png 1x, https://www.gigasavvy.com/app/uploads/2016/08/GFORE_Logo@2x.png 2x" alt="" />
                        </div>
                        <div class="logos__logo">
                            <img src="https://www.gigasavvy.com/app/uploads/2016/08/Northgate_Logo.png" srcset="https://www.gigasavvy.com/app/uploads/2016/08/Northgate_Logo.png 1x, https://www.gigasavvy.com/app/uploads/2016/08/Northgate_Logo@2x.png 2x" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            )
        }   
    };

export default Brands;