import React from "react"
import hero from "../images/kris_hero.jpg";

class Hero extends React.Component {

    componentDidMount() {
        function generateText(thing) {
            var words = thing.dataset.copy;
            var wordsArray = words.split('|');
    
            // manually use _.random
            var word = wordsArray[Math.floor(Math.random() * wordsArray.length)];
    
            thing.innerHTML = word;
        }
    
        function randomThing(thing) {
            setTimeout(function () {
                generateText(thing);
            }, 150);
    
            setTimeout(function () {
                generateText(thing);
            }, 300);
    
            setTimeout(function () {
                generateText(thing);
            }, 450);
        }

        const randomCopyElements = document.querySelectorAll(".random-copy");

        randomCopyElements.forEach(randomCopyElement => {
        /* Generate Random Time */
        var min = 1,
            max = 5;
        var rand = Math.floor(Math.random() * (max - min + 1) + min); //Generate Random number between 5 - 10


            window.setInterval(function(){
                randomThing(randomCopyElement);
            }, rand * 1000);
        });
    }

    render () {
        return (
            <section className="home">
                <div className="h-main-text-slider">
                    <h2 className="h-main-text">
                        I AM <br />
                        KRIS CHASE <br />
                        I <span className="random-copy" data-copy="BUILD|CREATE|ENGINEER">ENGINEER</span>  <br />
                        <span className="random-copy copy-white" data-copy="WEBSITES|PROCESSES|TEAMS|APPS|MEMORIES|IOT|CONNECTIONS">TEAMS</span> <br />

                    </h2>
                </div>
                <div className="h-showreel-container" data-cursor="Play">
                    <div className="h-showreel-box">
                        <div className="h-showreel-slider plane-inner">
                            <div className="h-showreel-scaler hover-plane">
                                <img srcSet={hero}  alt="Kris Chase" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
};

export default Hero;